import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import { useAuthStore } from '@/stores/AuthStore'
import { useEmployeeStore } from '@/stores/EmployeeStore'
import { useAssemblyStore } from '@/stores/AssemblyStore'

import { APISettings } from '@/config.js'

import { SoundEffect } from 'capacitor-sound-effect';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const pinia = createPinia();

const app = createApp(App)
  .use(IonicVue, {
    animated: false,
  })
  .use(pinia)
  .use(router)
  
router.isReady().then(() => {
  app.mount('#app');
});

const authStore = useAuthStore()
const employeeStore = useEmployeeStore()
const assemblyStore = useAssemblyStore()

router.beforeEach(async (to) => {
  if ('AppPage' === to.name) {
    return;
  }

  if ('LoginPage' === to.name && authStore.isAuthenticated) {
      return '/select';
  } else if ('LoginPage' !== to.name && !authStore.isAuthenticated) {
      return '/login';
  }

  if (authStore.isAuthenticated && 'EmployeeSelector' !== to.name && !employeeStore.hasActiveEmployee) {
      return '/employee';
  }

  if (authStore.isAuthenticated && 'UnitSelector' !== to.name && employeeStore.hasActiveEmployee && !assemblyStore.hasAssembly) {
      return '/select';
  }
})

window.addEventListener("error", (error) => {
    console.error(error);

    fetch(`${APISettings.baseURL}/log-error`, {
        method: 'POST',
        body: JSON.stringify({
            type: error.type,
            message: error.error.message,
            stack: error.error.stack,
            filename: error.filename,
            lineno: error.lineno,
            timestamp: Math.ceil(error.timeStamp),
            route: window.location.href,
            user: authStore.token,
            date: (new Date()).toString(),
            documentTitle: document.title,
        }),
    });
});

SoundEffect.loadSound({
  id: 'click',
  path: 'assets/sounds/click.mp3',
});
SoundEffect.loadSound({
  id: 'error',
  path: 'assets/sounds/error.mp3',
});