import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetch-wrapper";
import NonUniqueResultsError from "@/errors/NonUniqueResultsError";
import NoResultError from "@/errors/NoResultError";
import type { AssemblyFormType, Stage, Component, Action } from '@/types/AssemblyFormType.interface';

const getPath = (value, node, path = {}, depth = 0) => {
    if (value === node) {
        let _path = '';
        
        for (let i = 0; i < depth; i++) {
            _path += path[i];
        }

        return _path;
    }

    if (Array.isArray(value)) {
        for (const [index, _node] of value.entries()) {
            path[depth] = '[' + String(index) + ']';

            const result = getPath(_node, node, path, depth + 1);
            
            if (result) {
                return result;
            }
        }
    } else if (value && typeof value === 'object') {
        for (const key of Object.keys(value)) {
            path[depth] = (0 < depth ? '.' : '') + key;

            const result = getPath(value[key], node, path, depth + 1);
            
            if (result) {
                return result;
            }
        }
    }
}

export const useAssemblyStore = defineStore("AssemblyStore", {
    state: () => {
        return {
            isLoading:          undefined as boolean,
            error:              undefined as string | any,
            productUnitId:      undefined as number | null,
            number:             undefined as string | null,
            batchNumber:        undefined as string | null,
            salesOrderId:       undefined as number | null,
            productDescription: undefined as string | null,
            productNumber:      undefined as string | null,
            createdAt:          undefined as string | null,
            assemblyForm:       undefined as AssemblyFormType | null,
        }
    },

    actions: {
        async search(term: string, property = 'number') {
            this.reset();

            this.isLoading = true;

            let response;

            try {
                response = await fetchWrapper.get(`/api/product-units?${property}=${term}`);
            } catch (error) {
                this.error = error;
            } finally {
                this.isLoading = false;
            }

            const data = response.data;

            if (0 === data.length) {
                throw new NoResultError(`No result for "${term}".`);
            }

            if (1 < data.length) {
                throw new NonUniqueResultsError(`Multiple results for "${term}".`);
            }

            this.setData(data[0]);
        },

        async find(id: number) {
            this.reset();

            this.isLoading = true;

            let response;

            try {
                response = await fetchWrapper.get(`/api/product-units/${id}`);
            } catch (error) {
                this.error = error;
            } finally {
                this.isLoading = false;
            }

            const data = response.data;

            if (0 === data.length) {
                throw new NoResultError(`No result for "${id}".`);
            }

            this.setData(data);
        },

        setData(data) {
            this.productUnitId = data.id.split('/').pop();
            this.number = data.attributes.number;
            this.batchNumber = data.attributes.batchNumber;
            this.productDescription = data.attributes.productDescription;
            this.productNumber = data.attributes.productNumber;
            this.createdAt = data.attributes.createdAt.substr(0, 10);
            this.assemblyForm = data.attributes.assemblyForm;

            if ('salesOrder' in data.relationships) {
                this.salesOrderId = data.relationships.salesOrder.data.id.split('/').pop();
            }
        },

        async updateComponent(component: Component) {
            try {
                this.isLoading = true;
                component.error = null;

                await fetchWrapper.post(`/assembly-form/component`, {
                    productUnitId: this.productUnitId,
                    path: getPath(this.assemblyForm, component),
                    value: component.value,
                });
            } catch (error: any) {
                component.error = JSON.parse(error).errors[0].detail;
                console.error(component.error);
            } finally {
                this.isLoading = false;
            }

            return Promise;
        },

        async completeStage(stage: Stage) {
            try {
                this.isLoading = true;
                stage.error = null;

                await fetchWrapper.post(`/assembly-form/stage`, {
                    productUnitId: this.productUnitId,
                    path: getPath(this.assemblyForm, stage),
                });

                stage.completedAt = (new Date()).toISOString();
            } catch (error: any) {
                stage.error = JSON.parse(error).errors[0].detail;
                console.error(stage.error);
            } finally {
                this.isLoading = false;
            }

            return Promise;
        },

        async performAction(action: Action) {
            try {
                this.isLoading = true;
                action.isLoading = true;
                action.error = null;

                await fetchWrapper.post(`/assembly-form/action`, {
                    productUnitId: this.productUnitId,
                    path: getPath(this.assemblyForm, action),
                });

                action.completedAt = (new Date()).toISOString();
            } catch (error: any) {
                action.error = JSON.parse(error).errors[0].detail;
                console.error(action.error);
            } finally {
                this.isLoading = false;
                action.isLoading = false;
                action.lastAttemptAt = (new Date()).toISOString();
            }

            return Promise;
        },

        async revertAction(action: Action) {
            try {
                this.isLoading = true;
                action.isLoading = true;
                action.error = null;

                await fetchWrapper.post(`/assembly-form/revert-action`, {
                    productUnitId: this.productUnitId,
                    path: getPath(this.assemblyForm, action),
                });

                action.lastAttemptAt = null;
                action.completedAt = null;
            } catch (error: any) {
                action.error = JSON.parse(error).errors[0].detail;
                console.error(action.error);
            } finally {
                this.isLoading = false;
                action.isLoading = false;
            }

            return Promise;
        },
        
        reset() {
            this.isLoading = false;
            this.productUnitId = null;
            this.number = null;
            this.batchNumber = null;
            this.productDescription = null;
            this.productNumber = null;
            this.assemblyForm = null;
            this.salesOrderId = null;
        },
    },

    getters: {
        hasAssembly: (state) => !!state.productUnitId,
        nextIndex: (state) => {
            if (state.assemblyForm && 'stages' in state.assemblyForm) {
                for (const [index, stage] of state.assemblyForm.stages.entries()) {
                    if (!stage.completedAt) {
                        return index + 1;
                    }
                }
            }

            return state.assemblyForm?.stages?.length ?? 1;
        }
    }
})