import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router';
import AppPage from '../views/AppPage.vue'
import LoginPage from '../views/LoginPage.vue'
import AssemblyForm from '../views/AssemblyForm.vue'
import UnitSelector from '../views/UnitSelector.vue'
import EmployeeSelector from '../views/EmployeeSelector.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/app',
    name: 'AppPage',
    component: AppPage
  },
  {
    path: '/',
    redirect: '/select'
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/assembly-form/:id/:index',
    name: 'AssemblyForm',
    component: AssemblyForm
  },
  {
    path: '/select',
    name: 'UnitSelector',
    component: UnitSelector
  },
  {
    path: '/employee',
    name: 'EmployeeSelector',
    component: EmployeeSelector
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
