import { useAuthStore } from '@/stores/AuthStore';
import { APISettings } from '../config.js';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(APISettings.baseURL + url)
        };
        
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        
        return fetch(APISettings.baseURL + url, requestOptions).then(handleResponse);
    }
}

function authHeader() {
    const { accessToken, isAuthenticated } = useAuthStore();

    if (isAuthenticated) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    if (!response.ok) {
        const { isAuthenticated, logout } = useAuthStore();

        if ([401, 403].includes(response.status) && isAuthenticated) {
            logout();

            window.location.href = '/login';
        }

        return response.text().then(text => {
            const error = text.message || text;

            return Promise.reject(error);
        });
    }

    return response.text().then(text => {
        return JSON.parse(text);
    });
}