import { defineStore } from "pinia";
import { APISettings } from '../config.js';

export const useAuthStore = defineStore("AuthStore", {
    state: () => {
        return {
            isLoading: false,
            error: null,
            accessToken: localStorage.getItem('access_token'),
            refreshToken: localStorage.getItem('refresh_token'),
            expiresAt: parseInt(localStorage.getItem('expires_at')),
        }
    },
    actions: {
        async authenticate(username, password) {
            this.logout();

            this.isLoading = true;

            let response;

            try {
                response = await fetch(`${APISettings.baseURL}/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        grant_type: 'password',
                        username: username,
                        password: password,
                        client_id: '3c4ec2b478c71c45364e2490116ce3c4',
                        client_secret: '8b0d7fba6b78fd6fc287902ac8fbf97b776c45aca7b15e42a1d84c588b42c9d1da480d27a8ac4cf78c9a9dab68d68de66254b2406e4eb4b0134bb637afbf1928',
                    })
                });
            } catch (error) {
                this.error = error;
                console.log(error);
            } finally {
                this.isLoading = false;
            }

            const body = await response.json();

            localStorage.setItem('access_token', body.access_token);
            localStorage.setItem('refresh_token', body.refresh_token);
            localStorage.setItem('expires_at', (Date.now() / 1000) + body.expires_in);

            this.accessToken = body.access_token;
            this.refreshToken = body.refresh_token;
            this.expiresAt = (Date.now() / 1000) + body.expires_in;
        },

        logout() {
            this.isLoading = false;
            this.error = null;
            this.accessToken = null;
            this.refreshToken = null;
            this.expiresAt = null;

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('expires_at');
        },
    },
    getters: {
        isAuthenticated: (state) => {
            return state.accessToken && state.expiresAt > (Date.now() / 1000);
        },
        token: (state) => {
            return state.accessToken.substring(0, 255);
        },
    }
})