import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetch-wrapper";
// import NoResultError from "@/errors/NoResultError";
import type { EmployeeType } from '@/types/EmployeeType.interface';

export const useEmployeeStore = defineStore("EmployeeStore", {
    state: () => {
        return {
            isLoading:      undefined as boolean,
            error:          undefined as string | any,
            activeEmployee: JSON.parse(localStorage.getItem('active_employee')),
            employees:      JSON.parse(localStorage.getItem('employees')),
        }
    },

    actions: {
        async load() {
            // if (this.employees && 0 < this.employees.length) {
            //     return Promise;
            // }

            this.isLoading = true;

            let response;

            try {
                response = await fetchWrapper.get(`/api/assembly-employees`);
            } catch (error) {
                this.error = error;
            } finally {
                this.isLoading = false;
            }

            if (0 === response.data.length) {
                this.error = `No result for assembly employees".`;
                // throw new NoResultError(`No result for assembly employees".`);
            }

            this.employees = response.data.map((hash) => {
                return {
                    name: hash.attributes.name,
                    level: hash.attributes.level,
                    language: hash.attributes.language,
                    enabled: hash.attributes.enabled,
                }
            });

            localStorage.setItem('employees', JSON.stringify(this.employees));

            return Promise;
        },

        clear () {
            localStorage.removeItem('employees');
        },

        setActive (employee: EmployeeType) {
            this.activeEmployee = employee;
            
            localStorage.setItem('active_employee', JSON.stringify(employee));
        },

        setActiveByName (name: string) {
            const employees = this.employees.filter((employee) => {
                return employee.name === name;
            });

            if (1 === employees.length) {
                this.activeEmployee = employees[0];

                localStorage.setItem('active_employee', JSON.stringify(employees[0]));
            } else {
                throw new Error(`No employee found for '${name}'`);
            }
        },
    },

    getters: {
        hasActiveEmployee: (state) => !!state.activeEmployee,
    }
})